import React from 'react';
import MenuLogo from './MenuLogo';
import MenuWrapper from './MenuWrapper';
import PropTypes from 'prop-types';
import { sideBarOpen } from '../../atoms/sidebarAtom';
import { useRecoilState } from 'recoil';

const Menu = props => {
  const [isNavOpen] = useRecoilState(sideBarOpen);
  return (
    <div className={`sidebar ${!isNavOpen && 'sidebar-close-width'}`}>
      <div>
        <MenuLogo />
        <MenuWrapper menuItems={props.items} />
      </div>

    </div>
  );
};

Menu.propTypes = {
  items: PropTypes.array.isRequired
};

export default Menu;