
const helper = {};

helper.isValid = tag => {
  const noSlash = tag.indexOf('/') === -1;
  if (noSlash) {
    return false;
  }
  const keys = tag.split('/');
  if (keys.length !== 2) {
    return false;
  }
  const month = keys[0];
  const year = keys[1];

  const validMonth = !(isNaN(month) || parseInt(month, 10) > 13);
  const validYear = !isNaN(year) && ((year > 1900 && year < 2100) || (year < 50));
  return validMonth && validYear;
};

helper.pure = competence => {
  const keys = competence.toString().split('/');
  let month = keys[0];
  if (month.length === 1) {
    month = '0' + month;
  }
  let year = keys[1];
  if (year.length === 2) {
    year = '20' + year;
  }
  return `${year}${month}`;
};

helper.format = competence => {
  const ano = competence.toString().substring(0, 4);
  const mes = competence.toString().substring(4, 6);
  return `${mes}/${ano}`;
};

helper.getYear = competence => {
  const ano = competence.toString().substring(0, 4);
  return ano;
};

helper.getMonth = competence => {
  const mes = competence.toString().substring(4, 6);
  return mes;
};

export default helper;