import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const listaFiltrosAtom = {
  exibirColigada: true,
  exibirFilial: true,
};

export const exibirFiltrosAtom = atom({ key: 'exibirFiltrosAtom', default: { ...listaFiltrosAtom }, effects_UNSTABLE: [persistAtom] });