import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Menu from './menu/Menu';
import Header from './common/Header';
import Footer from './common/Footer';
import serviceUser from '../api/services/userServices';
import { useRecoilState } from 'recoil';
import { showFullPageAtom } from '../atoms/showFullPageAtom';
import { menuUserAtom } from '../atoms/menuUserAtom';
import { sideBarOpen } from '../atoms/sidebarAtom';
import { RoutePermissionProvider } from '../commons/useRoutePermission';
import { clarity } from 'react-microsoft-clarity';

const App = props => {
  const [menuUser, setMenuUser] = useRecoilState(menuUserAtom);
  const [showFullPage] = useRecoilState(showFullPageAtom);
  const [isNavOpen] = useRecoilState(sideBarOpen);
  const MENU_GERAL = 1;
  const CLARITY_GUARDIAN_ID = 'nxxzvu7c5k';

  useEffect(() => {
    clarity.init(CLARITY_GUARDIAN_ID);
    if (menuUser.length) return;
    serviceUser.getMenuUser().then(res => {
      if (res?.data) {
        setMenuUser(res.data);
      }
    });
  }, []);

  const getMenu = () => {
    if (!showFullPage) {
      return <Menu items={menuUser.filter(m => m.menuType === MENU_GERAL)} />;
    }
  };

  const getHeader = () => {
    if (!showFullPage) {
      return <Header />;
    }
  };

  const getFooter = () => {
    if (!showFullPage) {
      return <Footer />;
    }
  };

  const parentStyle = !showFullPage ? 'wrapper' : '';
  const childStyle = !showFullPage ? 'main-panel' : 'wrapper-fullpage';
  const isNavOpenClass = !isNavOpen && 'sidebar-closed main-panel-closed';

  return (
    <div className={parentStyle}>
      {getMenu()}
      <div className={`${childStyle} ${isNavOpenClass}`}>
        {getHeader()}
        {props.children}
        {getFooter()}
      </div>
    </div>
  );
};

App.propTypes = {
  children: PropTypes.object.isRequired
};

const AppWithRoutePermission = props => {
  return (
    <RoutePermissionProvider>
      <App {...props} />
    </RoutePermissionProvider>
  );
};

AppWithRoutePermission.propTypes = {
  children: PropTypes.object.isRequired
};

export default AppWithRoutePermission;
