import apiUtils from '../apiUtils';

const service = {};
const serviceName = 'paramRow';

service.getFiliais = () => {
  return apiUtils.exec(`${serviceName}`);
};

service.getFiltros = () => {
  return apiUtils.exec(`${serviceName}/Filtros/`);
};

service.getAll = () => {
  return apiUtils.exec(`${serviceName}/All`);
};

service.post = paramRow => {
  return apiUtils.exec(`${serviceName}`, apiUtils.getFetchParameters('POST', paramRow));
};

service.put = paramRow => {
  return apiUtils.exec(`${serviceName}`, apiUtils.getFetchParameters('PUT', paramRow));
};

service.delete = paramRow => {
  return apiUtils.exec(`${serviceName}`, apiUtils.getFetchParameters('DELETE', paramRow));
};

export default service;
