import React from 'react';
import PropTypes from 'prop-types';
import Menu from '../menu/Menu';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { useRecoilState } from 'recoil';
import { showFullPageAtom } from '../../atoms/showFullPageAtom';
import { menuUserAtom } from '../../atoms/menuUserAtom';
import { sideBarOpen } from '../../atoms/sidebarAtom';
import { RoutePermissionProvider } from '../../commons/useRoutePermission';

const App = props => {
  const [menuUser] = useRecoilState(menuUserAtom);
  const [showFullPage] = useRecoilState(showFullPageAtom);
  const [isNavOpen] = useRecoilState(sideBarOpen);
  const MENU_ADM = 2;

  const getMenu = () => {
    return <Menu items={menuUser.filter(m => m.menuType === MENU_ADM)} />;
  };

  const getHeader = () => {
    return <Header />;
  };

  const getFooter = () => {
    return <Footer />;
  };

  const parentStyle = !showFullPage ? 'wrapper' : '';
  const childStyle = !showFullPage ? 'main-panel' : 'wrapper-fullpage';
  const isNavOpenClass = !isNavOpen && 'sidebar-closed main-panel-closed';

  return (
    <div className={parentStyle}>
      {getMenu()}
      <div className={`${childStyle} ${isNavOpenClass}`}>
        {getHeader()}
        {props.children}
        {getFooter()}
      </div>
    </div>
  );
};

const AppWithRoutePermission = props => {
  return (
    <RoutePermissionProvider>
      <App {...props} />
    </RoutePermissionProvider>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired
};

AppWithRoutePermission.propTypes = {
  children: PropTypes.object.isRequired
};

export default AppWithRoutePermission;