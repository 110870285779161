import React from 'react';
import { useRecoilState } from 'recoil';
import { sideBarOpen } from '../../atoms/sidebarAtom';
import logo from '../../assets/img/icons/LogoGuardianNova-h.png';
import menuClosedLogo from '../../assets/img/icons/LogoGuardianNova.png';

const MenuLogo = () => {
  const [isNavOpen, setIsNavOpen] = useRecoilState(sideBarOpen);
  const menuLogo = isNavOpen ? logo : menuClosedLogo;
  return (
    <div className={`logo d-flex ${isNavOpen ? 'flex-row' : 'flex-column'} align-items-center`}>
      <button className="mt-2 bg-transparent border-0" onClick={() => {
        setIsNavOpen(!isNavOpen);
      }}>
        <span className="material-icons">
          menu
        </span>
      </button>
      <img src={menuLogo} alt="Guardian" width={isNavOpen ? 155 : 50} />
    </div >
  );
};


export default MenuLogo;
