import React from 'react';
import MenuItem from './MenuItem';
import PropTypes from 'prop-types';
import { sideBarOpen } from '../../atoms/sidebarAtom';
import { useRecoilState } from 'recoil';

const MenuWrapper = ({ menuItems }) => {
  const [isNavOpen] = useRecoilState(sideBarOpen);
  const itemList = [];
  menuItems.forEach((item) => {
    itemList.push(<MenuItem key={item.id} item={item} />);
  });

  return (
    <div className={isNavOpen ? 'sidebar-wrapper' : 'sidebar-wrapper-closed'}>
      <ul className="nav">
        {itemList}
      </ul>
    </div>
  );
};

MenuWrapper.propTypes = {
  menuItems: PropTypes.array.isRequired
};

export default MenuWrapper;
