import React, { useState } from 'react';
import logo from '../../assets/img/icons/LogoGuardianNova.png';
import loginServices from '../../api/services/loginServices';
import { useRecoilState } from 'recoil';
import { userAtom } from '../../atoms/userAtom';
import '../../assets/css/login.css?v=1.0.0';
import './login.css';

const ChangePassword = () => {
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [user, setUser] = useRecoilState(userAtom);

  const changePassword = e => {
    e.preventDefault();

    if (password !== confirm) {
      return setErrorMessage('As senhas digitadas precisam ser identicas');
    }

    loginServices.changePassword(user.accessInfo.internalToken, password).then(res => {
      if (res?.response?.Type === 'Success') {
        const localUser = { ...user };
        localUser.changePassword = false;
        setUser(localUser);
      } else { 
        setErrorMessage(res?.response.Message);
      }

    });
  };

  const handleChange = (event, set) => {
    const target = event.target;
    const value = target.value;

    set(value);
  };

  const getComponent = () => {
    let errorComponent = null;
    if (errorMessage) {
      errorComponent =
        <div className="alert alert-danger login-alert">
          {errorMessage}
        </div>;
    }
    const component =
      <form className="form-signin">
        <h5>Preencha os campos abaixo para trocar sua senha:</h5>
        <input type="password" className="form-control" name="password" value={password} onChange={e => handleChange(e, setPassword)} placeholder="Nova Senha" required />
        <input type="password" className="form-control" name="confirm" value={confirm} onChange={e => handleChange(e, setConfirm)} placeholder="Confirme a Senha" required />
        {errorComponent}
        <button className="login-change-password btn btn-lg btn-success btn-block" onClick={changePassword}>
          Confirmar
        </button>
      </form>;
    return component;
  };

  return (
    <div className="login-bg">
      <div className="container" data-color="purple">
        <div className="row login-row">
          <div className="col-sm-6 col-md-4 col-md-offset-4 d-flex justify-content-center">
            <div className="account-wall">
              <img src={logo} alt="Guardian" className="logo-img" />
              <br />
              {getComponent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
