import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

function isIncludingString(string, option) {
  let result = false;
  if (
    !string ||
    option.label.toString().toLowerCase().includes(string.toLowerCase()) ||
    option.value.toString().toLowerCase().includes(string.toLowerCase())
  ) {
    result = true;
  }
  return result;
}

class Lookup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
      disabled: false,
      placeholder: 'Selecione...',
      multi: true,
      multiSelect: false,
      newVersion: this.props.newVersion || false
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange(value) {
    if (this.state.newVersion) return this.props.setSelected(value);
    if (!this.state.multi) {
      this.setState({ value: [value] });
      return this.props.setSelected(value.value);
    }

    let ids = '';
    value.forEach(v => ids += `${v.value},`);
    if (ids) {
      ids = ids.slice(0, -1);
    }
    this.props.setSelected(ids);
  }

  initialValidations(nextProps) {
    if (nextProps.disabled !== undefined)
      this.setState({ disabled: nextProps.disabled });

    if (nextProps.placeholder)
      this.setState({ placeholder: nextProps.placeholder });

    if (!nextProps || !nextProps.options || nextProps.options.length === 0) {
      return;
    }
    const firstItem = nextProps.options[0];
    if (!Object.prototype.hasOwnProperty.call(firstItem, nextProps.fieldLabel) 
    || !Object.prototype.hasOwnProperty.call(firstItem, nextProps.fieldValue)) {
      throw new Error({ message: 'Tipo inválido para o componente de lookup.' });
    }


    let value = [];
    const formatedOptions = this.defineFormattedOptions(nextProps);

    if (nextProps.multi !== undefined) {
      this.setState({ multi: nextProps.multi });
    }

    if (nextProps.selectValue) {
      const v = nextProps.selectValue;
      value.push(formatedOptions.find(o => (v === o.value || parseInt(v, 10) === o.value)));
    } else if (nextProps.selectValues && nextProps.selectValues.length) {
      // multi
      nextProps.selectValues.forEach(v => {
        value.push(formatedOptions.find(o => (v === o.value || parseInt(v, 10) === o.value)));
      });
    } else if (nextProps.options.length === 1) {
      // this.handleSelectChange(firstItem[nextProps.fieldValue].toString());
    }

    this.setState({ value });
  }

  componentDidUpdate = prevProps => {
    if (prevProps.options !== this.props.options
      || prevProps.selectValue !== this.props.selectValue
      || prevProps.selectValues !== this.props.selectValues
      || prevProps.disabled !== this.props.disabled) {
      this.initialValidations(this.props);
    }
  }

  defineFormattedOptions = props => {
    if (!props || !props.options)
      return;
    const formatedOptions = props.options.map(o => {
      const item = {};
      item.label = o[props.fieldLabel];
      item.value = o[props.fieldValue];
      return item;
    });

    if (this.state.multi && formatedOptions.length > 0)
      formatedOptions.unshift({ value: 'all', label: 'Selecionar Todos' });

    return formatedOptions;
  }

  componentDidMount() {
    this.initialValidations(this.props);
  }

  filterOption = ({ label, value }, string) => {
    if (value === 'all') {
      return true;
    } else if (string) {
      return label.toLowerCase().includes(string.toLowerCase()) || value.toString().toLowerCase().includes(string.toLowerCase());
    } else {
      return true;
    }
  };

  onInputChange = (string, { action }) => {
    if (action === 'input-change') {
      this.setState({
        searchField: string
      });
    }
  };

  onChange = (opt, { option }) => {
    let newOpts = opt;
    let string = this.state.searchField;
    const options = this.defineFormattedOptions(this.props);

    if (option && option.value === 'all') {
      let filteredOptions = [...options];

      filteredOptions = filteredOptions.filter(
        filteredOption =>
          isIncludingString(string, filteredOption) &&
          !newOpts.includes(filteredOption)
      );

      string = null;
      newOpts = filteredOptions
        .filter(newOpt => newOpt.value !== 'all');
    }

    if (this.state.newVersion) return this.props.setSelected(newOpts);
    if (!this.state.multi) {
      this.setState({ value: [newOpts] });
      return this.props.setSelected(newOpts.value);
    }

    let ids = '';
    newOpts.forEach(v => ids += `${v.value},`);
    if (ids) {
      ids = ids.slice(0, -1);
    }
    this.props.setSelected(ids);

    this.setState({
      searchField: string,
      value: newOpts
    });
  };

  render() {
    const formatedOptions = this.defineFormattedOptions(this.props);
    return (
      <Select
        filterOption={this.filterOption}
        onInputChange={this.onInputChange}
        classNamePrefix={'react-select'}
        menuContainerStyle={{ zIndex: 5 }}
        isMulti={this.state.multi}
        onChange={this.onChange}
        options={formatedOptions}
        placeholder={this.state.placeholder}

        value={this.state.value}
        isDisabled={this.state.disabled}
      />
    );
  }
}

Lookup.propTypes = {
  options: PropTypes.array.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  fieldValue: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  selectValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  selectValues: PropTypes.array,
  disabled: PropTypes.bool,
  newVersion: PropTypes.bool
};

export default Lookup;
