import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const defaultSettings = {
  apiUrl: null,
  appName: null
};

export const settingsAtom = atom(
  { 
    key: 'settingsAtom', 
    default: { ...defaultSettings }, effects_UNSTABLE: [persistAtom] 
  });
