import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

class DynamicLookup extends Component {
  static propTypes = {
    validation: PropTypes.func.isRequired,
    setSelected: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    values: PropTypes.array,
    setNotConfirmed: PropTypes.func
  };
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
      value: this.props.values,
    };
  }

  componentDidUpdate = prevProps => {
    if (prevProps.values !== this.props.values) {
      this.setState({ value: this.props.values });
    }
  }

  handleChange = (value, action) => {
    if (action.action === 'remove-value' || action.action === 'clear') {
      this.props.setSelected(value);
    }
    this.setState({ value });
  };

  handleInputChange = inputValue => {
    this.setState({ inputValue });
  };

  handleKeyDown = event => {
    const { inputValue, value } = this.state;

    if (!inputValue) return;
    if (event.key === 'Enter' || event.key === 'Tab') {
      this.setState({ inputValue: '' });
      const valid = this.props.validation(inputValue) && !value.find(v => v.value === inputValue);
      if (!valid) return;
      const newValue = [...value, createOption(inputValue)];
      this.setState({ value: newValue });
      this.props.setSelected(newValue);
      event.preventDefault();
    }
  };

  onBlur = inputValue => {
    if (this.props.setNotConfirmed) this.props.setNotConfirmed(inputValue);
  }

  render = () => {
    const { inputValue, value } = this.state;
    return (
      <CreatableSelect
        onBlur={() => this.onBlur(this.state.inputValue)}
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown}
        placeholder={this.props.placeholder || 'Digite os valores desejados aqui...'}
        value={value}
      />
    );
  }
}
export default DynamicLookup;
