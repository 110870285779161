import React, { Suspense } from 'react';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import App from './AdministrationApp';
import AsyncComponent from '../AsyncComponent';

const TaskManager = () => import('./taskManager/TaskManagerPage');
const SqlExecutor = () => import('./sqlExecutor/SqlExecutorPage');
const Update = () => import('./global/GlobalUpdatePage');
const User = () => import('./user/UserPage');
const Tag = () => import('./tag/TagPage');
const ParamsRowPage = () => import('./paramsRow/ParamsRowPage');
const SecurityProfile = () => import('./securityProfile/ProfilePage');
const Welcome = () => import('../../components/welcome/welcomePage');
const ContextErp = () => import('./contextErp/ContextErpPage');
const AccountingConfig = () => import('./accountingConfig/AccountingConfigPage');
const Company = () => import('./company/CompanyPage');
const UserAccess = () => import('../../components/administration/userAccess/UserAccessPage');
const Hash = () => import('../../components/administration/hash/HashPage');
const ParameterERP = () => import('./parameterERP/ParameterERPPage');
const Scheduler = () => import('./scheduler/SchedulerPage');
const AlertScheduler = () => import('./alertsScheduler/AlertsSchedulerPage');
const CustomQuery = () => import('./customQuery/CustomQueryPage');
const ActivateAnalysis = () => import('./activateAnalysis/ActivateAnalysisPage');
const Profile = () => import('../common/profile/ProfilePage');

const AdmRoutes = () => (
  <HashRouter>
    <Suspense fallback={<div>Carregando...</div>}>
      <App>
        <Routes>
          <Route path='/taskManager' element={<AsyncComponent importComponent={TaskManager} showCompanies />} />
          <Route exact path='/sqlexecutor' element={<AsyncComponent importComponent={SqlExecutor} />} />
          <Route exact path='/update' element={<AsyncComponent importComponent={Update} />} />
          <Route exact path='/user' element={<AsyncComponent importComponent={User} />} />
          <Route exact path='/securityProfile' element={<AsyncComponent importComponent={SecurityProfile} />} />
          <Route exact path='/tag' element={<AsyncComponent importComponent={Tag} />} />
          <Route exact path='/paramsRow' element={<AsyncComponent importComponent={ParamsRowPage} />} />
          <Route exact path='/welcome' element={<AsyncComponent importComponent={Welcome} />} />
          <Route exact path='/contexterp' element={<AsyncComponent importComponent={ContextErp} />} />
          <Route exact path='/accountingconfig' element={<AsyncComponent importComponent={AccountingConfig} />} />
          <Route exact path='/company' element={<AsyncComponent importComponent={Company} />} />
          <Route exact path='/hash' element={<AsyncComponent importComponent={Hash} />} />
          <Route exact path='/parameterERP' element={<AsyncComponent importComponent={ParameterERP} />} />
          <Route path='/userAccess' element={<AsyncComponent importComponent={UserAccess} />} />
          <Route path='/scheduler' element={<AsyncComponent importComponent={Scheduler} />} />
          <Route path='/alertsScheduler' element={<AsyncComponent importComponent={AlertScheduler} />} />
          <Route path='/customQuery' element={<AsyncComponent importComponent={CustomQuery} />} />
          <Route path='/activateAnalysis' element={<AsyncComponent importComponent={ActivateAnalysis} />} />

          <Route path='/profile' element={<AsyncComponent importComponent={Profile} />} />

          <Route path='*' element={<Navigate to='/welcome' />} />
        </Routes>
      </App>
    </Suspense>
  </HashRouter>
);

export default AdmRoutes;
