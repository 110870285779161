import React, { useContext, createContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { userAtom } from '../atoms/userAtom';
import { useRecoilState } from 'recoil';
import PropTypes from 'prop-types';

const RoutePermissionContext = createContext();

export const RoutePermissionProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useRecoilState(userAtom);

  useEffect(() => {
    if (!user.hasRouteAccess) {
      const newUser = { ...user, hasRouteAccess: true };
      setUser(newUser);
      navigate('/welcome');
    }
  }, [user.hasRouteAccess]);

  return (
    <RoutePermissionContext.Provider value={null}>
      {children}
    </RoutePermissionContext.Provider>
  );
};

RoutePermissionProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useRoutePermission = () => {
  return useContext(RoutePermissionContext);
};