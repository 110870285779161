import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import helper from '../helper/competenceHelper';
import Lookup from '../dynamicLookup/DynamicLookup';
import { useRecoilState } from 'recoil';
import { competencesAtom } from '../../../atoms/competencesAtom';

const DgCompetence = props => {
  const [competences, setCompetences] = useRecoilState(competencesAtom);
  const [tags, setTags] = useState([]);
  const [values, setValues] = useState([]);
  const [notConfirmed, setNotConfirmed] = useState('');

  useEffect(() => {
    loadCompetences();
  }, [props]);

  const loadCompetences = () => {
    const tags = competences.map(c => helper.format(c));
    const localValues = tags.map(t => {
      return { label: t, value: t };
    });
    setTags(tags);
    setValues(localValues);
  };

  const handleChange = localValues => {
    const localTags = localValues.map(v => v.value);
    setTags(localTags);
    setNotConfirmed('');
  };

  const confirm = () => {
    let localCompetences = tags.map(t => helper.pure(t));
    if (notConfirmed && helper.isValid(notConfirmed)) {
      localCompetences.push(helper.pure(notConfirmed));
    }
    localCompetences = [...new Set(localCompetences)];
    setCompetences([...localCompetences]);
    props.methods.onClose();
  };

  const close = () => {
    props.methods.onClose();
  };

  const placeholder = 'adicione uma competência';
  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-lg" onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-lg">Selecione as competências que deseja visualizar</Modal.Title>
      </Modal.Header>
      <hr />
      <Modal.Body>
        <div>
          <h5>Adicione uma competência no formato mês/ano</h5>
          <div className="row">
            <div className="col-md-10">
              <Lookup validation={helper.isValid} setSelected={handleChange} values={values}
                placeholder={placeholder} setNotConfirmed={setNotConfirmed} />
            </div>
          </div>
        </div>
      </Modal.Body>
      <hr />
      <Modal.Footer>
        <div>
          <button onClick={confirm} className="btn btn-success">Confirmar</button>
          <button onClick={close} className="btn btn-dark">Cancelar</button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

DgCompetence.propTypes = {
  methods: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired
};

export default DgCompetence;
