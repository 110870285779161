import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { sideBarOpen } from '../../atoms/sidebarAtom';
import { useRecoilState } from 'recoil';

const MenuItem = ({ item }) => {
  const [isNavOpen] = useRecoilState(sideBarOpen);
  return (
    <li key={item.id} data-cy="menuItem" className="guardian-menu-item" >
      <NavLink to={item.href} className={({ isActive }) =>
        `${(isActive ? 'guardian-menu-anchor selectedMenu' : 'guardian-menu-anchor unselectedMenu')} ${!isNavOpen && 'guardian-menu-anchor-closed'}`}>
        <i className={`material-icons guardian-menu-icon ${!isNavOpen && 'guardian-menu-anchor-closed'}`} >{item.icon}</i>
        {isNavOpen && <span>{item.name}</span>}
      </NavLink>
    </li>
  );
};

MenuItem.propTypes = {
  item: PropTypes.object.isRequired
};

export default MenuItem;
