import apiUtils from '../apiUtils';

const service = {};
const serviceName = 'login';

service.login = (user, pwd, idClient) => {
  const body = {
    userName: user,
    password: pwd,
    IDClient: idClient
  };
  const removeAuth = true;
  return apiUtils.exec(serviceName, apiUtils.getFetchParameters('POST', body, removeAuth));
};

service.getResult = id => {
  return apiUtils.exec(serviceName + '/result/' + id);
};

service.changePassword = (internalToken, password) => {
  const body = {
    internalToken,
    password
  };
  const removeAuth = true;
  return apiUtils.exec(`${serviceName}/ChangePassword`, apiUtils.getFetchParameters('POST', body, removeAuth));
};

export default service;
