import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import logo from '../../assets/img/icons/LogoGuardianNova.png';
import clientServices from '../../api/services/clientServices';
import messages from '../../commons/constMessages';
import packageInfo from '../../../package.json';
import endpointServices from '../../services/clientServices';
import LoginGuardian from './LoginGuardian';
import LoginAzure from './LoginAzure';
import LoginSaml from './LoginSaml';
import loginActions from '../../services/loginServices';
import { PulseLoader } from 'react-spinners';
import { useRecoilState } from 'recoil';
import { userAtom } from '../../atoms/userAtom';
import '../../assets/css/login.css?v=1.0.0';
import './login.css';

const LoginPage = props => {
  const [user, setUser] = useRecoilState(userAtom);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const loginModes = [
    { id: 1, component: LoginGuardian },
    { id: 2, component: LoginGuardian },
    { id: 3, component: LoginAzure },
    { id: 4, component: LoginSaml },
  ];

  const showErrorMessage = message => {
    setErrorMessage(message);
    setIsLoading(false);
  };

  useEffect(() => {
    initiateClient();
    if (props.errorMessage) {
      showErrorMessage(props.errorMessage);
    }
  }, [props.errorMessage]);

  useEffect(() => {
    if (user && user.userName && user.password && user.client && user.client.azureAd) {
      proceedLogin();
    }
  }, [user.userName, user.password]);

  const initiateClient = () => {
    const endPoint = endpointServices.resolveEndpoint(window.location.href);
    clientServices.get(endPoint).then(client => {
      if (!client || !client.id) {
        showErrorMessage(messages.ErrClientNotFound);
        return;
      }

      const localUser = { ...user, endPoint, client };
      setUser(localUser);
      setIsLoading(false);
    })
      .catch(() => {
        showErrorMessage(messages.ErrApiNotFound);
      });
  };

  const getClientId = () => {
    if (user && user.client) return user.client.id;
  };

  const proceedLogin = inputUser => {
    setIsLoading(true);
    setErrorMessage('');

    const localUser = !inputUser ? { ...user } : { ...inputUser };
    loginActions.login(localUser).then(data => {
      localUser.password = '';
      if (!data.authenticated) {
        localUser.userName = '';
        localUser.authError = true;
        setUser(localUser);
        showErrorMessage(data.message);
        return;
      }
      localUser.authError = false;
      localUser.name = data.usuario;
      localUser.userName = data.usuario;
      localUser.isAuth = true;
      localUser.accessInfo = data;
      localUser.changePassword = data.changePassword;
      setUser(localUser);
      setIsLoading(false);
    })
      .catch(() => {
        showErrorMessage(messages.ErrApiNotFound);
      });
  };

  const getComponent = () => {
    if (isLoading) {
      const loader = <center><PulseLoader data-cy="loginLoader" size={10} color={'#333'} loading={true} /></center>;
      return loader;
    }

    const component =
      <form className="form-signin">
        {getLoginComponent()}
        <div className="right">
          <label className="version">V:{packageInfo.version} | C:{getClientId()}</label>
        </div>
      </form>;
    return component;
  };

  const getLoginComponent = () => {
    let errorComponent = null;
    if (errorMessage) {
      errorComponent =
        <div className="alert alert-danger login-alert">
          {errorMessage}
        </div>;
    }
    
    let loginMode = loginModes.find(l => l.id === user?.client?.loginMode);
    if (!loginMode) loginMode = loginModes.find(l => l.id === 1);
    const LoginCpn = loginMode.component;

    const component =
      <LoginCpn user={user} setUser={setUser} proceedLogin={proceedLogin} showErrorMessage={showErrorMessage} isLoading={isLoading} setIsLoading={setIsLoading} errorMessage={errorMessage} >
        {errorComponent}
      </LoginCpn>;
    return component;
  };

  return (
    <div className="login-bg">
      <div className="container" data-color="purple">
        <div className="row login-row">
          <div className="col-sm-6 col-md-4 col-md-offset-4 d-flex justify-content-center">
            <div className="account-wall">
              <img src={logo} alt="Guardian" className="logo-img" />
              <br />
              {getComponent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

LoginPage.propTypes = {
  errorMessage: PropTypes.string
};

export default LoginPage;
