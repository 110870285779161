import React, { useEffect, useState, lazy } from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { showHeaderCompetenceAtom } from '../atoms/showHeaderCompetenceAtom';
import { showHeaderCompaniesAtom } from '../atoms/showHeaderCompaniesAtom.js';
import { showHeaderFilialAtom } from '../atoms/showHeaderFilialAtom';

const AsyncComponent = props => {
  const [, setShowCompetence] = useRecoilState(showHeaderCompetenceAtom);
  const [, setShowCompanies] = useRecoilState(showHeaderCompaniesAtom);
  const [, setShowFilial] = useRecoilState(showHeaderFilialAtom);
  const [Component, setComponent] = useState(null);

  useEffect(() => {
    if (props.importComponent) {
      setComponent(lazy(props.importComponent));
      setShowCompetence(props.showCompetence);
      setShowCompanies(props.showCompanies);
      setShowFilial(props.showFilial);
    }
  }, [props.importComponent]);

  if (!Component) return (<div>Carregando...</div>);
  return (<Component />);
};

AsyncComponent.propTypes = {
  importComponent: PropTypes.func.isRequired,
  showCompetence: PropTypes.bool,
  showCompanies: PropTypes.bool,
  showFilial: PropTypes.bool
};

export default AsyncComponent;