import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import services from '../../../api/services/paramRowServices';
import { Modal } from 'react-bootstrap';
import Lookup from '../lookup/Lookup';
import { useRecoilState } from 'recoil';
import { filialsAtom } from '../../../atoms/filialsAtom';
import { companiesAtom } from '../../../atoms/companiesAtom';
import { PulseLoader } from 'react-spinners';

const DgFilial = props => {
  const [isLoading, setIsLoading] = useState(true);
  const [filials, setFilials] = useState([]);
  const [allFilials, setAllFilials] = useState([]);
  const [companies] = useRecoilState(companiesAtom);
  const [selectedFilials, setSelectedFilials] = useRecoilState(filialsAtom);
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    
    loadFilials();
  }, [companies]);

  useEffect(() => {
    filterFilials();
  }, [companies, allFilials]);

  const loadFilials = () => {
    if (!companies.length) {
      setFilials([]);
      setSelectedFilials([]);
      setSelectedValues([]);
      return;
    }
    setIsLoading(true);

    services.getFiliais().then(res => {
      if (res?.data) {
        const localFilials = [...res.data.map(r => {
          return { codColigada: r.codColigada, codFilial: `${r.codColigada}-${r.codFilial}`, name: `${r.codColigada}-${r.codFilial}-${r.nomeFilial}` };
        })];

        setAllFilials([...localFilials].sort(sortFilial));
        loadSelectedFilials();
      }
      setIsLoading(false);
    });
  };

  const filterFilials = () => {
    if (!companies.length) {
      setFilials([]);
      setSelectedFilials([]);
      setSelectedValues([]);
      return;
    }

    const filteredFilials = allFilials.filter(f =>  companies.some(c => c.idCompanyErp === f.codColigada));

    setFilials(filteredFilials);
  };

  const sortFilial = (a, b) => {
    const arrayA = a.codFilial.split('-');
    const arrayB = b.codFilial.split('-');
    const coligadaA = parseInt(arrayA[0]);
    const filialA = parseInt(arrayA[1]);
    const coligadaB = parseInt(arrayB[0]);
    const filialB = parseInt(arrayB[1]);

    if (coligadaA != coligadaB) {
      if (coligadaA > coligadaB) {
        return 1;
      } else {
        return -1;
      }
    } else if (filialA > filialB) {
      return 1;
    } else {
      return -1;
    }
  };

  const loadSelectedFilials = () => {
    const localSelectedValues = selectedFilials.map(c => c.codFilial);
    setSelectedValues(localSelectedValues);
  };

  const confirm = () => {
    if (!filials.length) { return; }

    const localSelectedFilials = selectedValues.map(s => {
      return structuredClone(filials.find(c => c.codFilial === s));
    });
    setSelectedFilials(localSelectedFilials);
    close();
  };

  const close = () => {
    props.methods.onClose();
  };

  const setSelectedValuesDdl = values => {
    const localSelectedValues = values.map(v => v.value);
    setSelectedValues(localSelectedValues);
  };

  const selectAll = () => {
    const localSelectedValues = filials.map(c => c.codFilial);
    setSelectedValues(localSelectedValues);
  };

  const getContent = () => {
    if (isLoading) {
      return <PulseLoader data-cy="dgFilialLoader" size={10} color={'#333'} loading={isLoading} />;
    }
    const placeholder = 'Adicione uma filial';
    let component =
      <>
        <Lookup
          options={filials}
          fieldLabel={'name'}
          fieldValue={'codFilial'}
          setSelected={setSelectedValuesDdl}
          selectValues={selectedValues}
          placeholder={placeholder}
          newVersion={true} />
        <button onClick={selectAll} className="btn btn-success">Todas</button>
      </>;

    if (companies.length && !filials.length) {
      component = <div>Nenhuma filial encontrada, favor conferir ou selecionar outra coligada</div>;
    } else if (!filials.length) {
      component = <div>Selecione primeiramente uma coligada</div>;
    }
    return component;
  };


  return (
    <Modal show={props.show} size="lg" aria-labelledby="contained-modal-title-lg" onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-lg">Selecione as filiais que deseja visualizar</Modal.Title>
      </Modal.Header>
      <hr />
      <Modal.Body>
        <div className="row">
          <div className="col-md-10">
            {getContent()}
          </div>
        </div>
      </Modal.Body>
      <hr />
      <Modal.Footer>
        <div>
          <button data-cy="confirmSelectFillials" onClick={confirm} className="btn btn-success">Confirmar</button>
          <button onClick={close} className="btn btn-dark">Cancelar</button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

DgFilial.propTypes = {
  methods: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired
};

export default DgFilial;
