import React from 'react';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import { createRoot } from 'react-dom/client';
import Start from './start';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './assets/css/material-dashboard.css?v=1.2.5';
import '../node_modules/toastr/build/toastr.min.css';
import './index.css';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <RecoilRoot>
    <RecoilNexus />
    <Start />
  </RecoilRoot>
);
