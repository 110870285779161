import React, { useEffect } from 'react';
import Routes from './routes';
import AdministrationRoutes from './components/administration/AdministrationRoutes';
import Login from './components/login/LoginPage';
import ChangePassword from './components/login/ChangePassword';
import clientServices from './services/clientServices';
import messages from './commons/constMessages';
import socialService from './api/services/socialServices';
import { useRecoilState } from 'recoil';
import { userAtom } from './atoms/userAtom';

const Start = () => {
  const [user] = useRecoilState(userAtom);

  useEffect(() => {
    if (user && user.accessInfo && user.accessInfo.internalToken) {
      socialService.postPeriodicSync();
    }
  }, [user.accessInfo.internalToken]);

  const getComponent = () => {
    let errorMessage = '';
    if (user.isAuth && !clientServices.persistEndpoint(user)) {
      errorMessage = messages.ErrMultiplasSecoes;
    } 

    if (!user.isAuth || errorMessage) {
      return <Login errorMessage={errorMessage} />;  
    }
    if (user.changePassword) {
      return <ChangePassword />;
    }

    return !user.accessingAdministrationMode ? <Routes /> : <AdministrationRoutes />;
  };

  return (getComponent());
};

export default Start;
