import apiUtils from '../apiUtils';

const service = {};
const serviceName = 'social';

service.post = () => {
  return apiUtils.exec(`${serviceName}/ExpireDate`, apiUtils.getFetchParameters('POST'));
};

service.postPeriodicSync = () => {
  return apiUtils.exec(`${serviceName}/PeriodicSync`, apiUtils.getFetchParameters('POST'));
};

service.getCertificate = certificate => {
  return apiUtils.exec(`${serviceName}/Certificate/${certificate}`);
};

service.getProtocol = (idEvent, idCompany) => {
  return apiUtils.exec(`${serviceName}/Protocol/${idEvent}/Company/${idCompany}`);
};

service.getGov = (protocol, idCompany) => {
  return apiUtils.exec(`${serviceName}/Gov/${protocol}/Company/${idCompany}`);
};

service.getRubric = (protocol, idCompany) => {
  return apiUtils.exec(`${serviceName}/Rubric/${protocol}/Company/${idCompany}`);
};

service.getVersion = () => {
  return apiUtils.exec(`${serviceName}/Version/`);
};

export default service;
