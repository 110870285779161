import apiUtils from '../apiUtils';

const service = {};
const serviceName = 'client';

service.get = (endPoint) => {
  return apiUtils.exec(`${serviceName}/${endPoint}`);
};

export default service;
